import React, { useState, useEffect, useCallback, useContext } from "react";
import { ErrorContext } from "../context/ErrorProvider";
import { useLocation } from "react-router-dom";
import axios from "axios";
import axiosRetry from "axios-retry";
import Details from "./profiles/lcrcProfiles/Details";
import Overview from "./profiles/lcrcProfiles/Overview";
import MoreInfo from "./profiles/lcrcProfiles/MoreInfo";
import Footer from "../components/footer/Footer";
import LoadingSpinner from "../components/spinner/LoadingSpinner";
import diagnosticProfileFunctions from "../functions/diagnosticProfileFunctions";
import ReadingSurveyModal from "../components/survey/ReadingSurveyModal";
import ListeningSurveyModal from "../components/survey/ListeningSurveyModal";
/**
 * @component Profile
 * @description displays a users RC/LC static diagnostic profile
 * @todo clean up left over code and add comments
 **/

const print = () => {
  //document.querySelector("meta[name=viewport]").setAttribute("content", "width=600");
  // const element = props.targetRef.current;
  const element = document.querySelector(".bodyItems");
  let arrowsToHide = element.querySelectorAll(".centerer :nth-child(1)");
  arrowsToHide.forEach((arrow) => {
    if (arrow.style !== null) {
      arrow.style.display = "none";
    }
  });
  let currentSelectionEls = element.querySelectorAll(".active");
  currentSelectionEls.forEach((el) => {
    el.classList.add("temporaryActive");
  });
  let allTabsAndContent = element.querySelectorAll(".tabContent, .tabLabel");
  allTabsAndContent.forEach((el) => {
    if (el.classList.contains("active")) {
      //skip
    } else {
      el.classList.add("active");
    }
  });
  let theEls2 = element.querySelectorAll(".grInfo p.infoTagline, .grInfo button");
  theEls2.forEach((el) => {
    el.style.visibility = "hidden";
  });
  element.querySelector(".tabContent").style.border = "none"; //was 1px solid #ddd
  window.print();
};


const Profile = (props) => {
  const [, errorDispatch] = useContext(ErrorContext);

  //Gather initial data for this profile...
  const location = useLocation();
  const pdfSegment = React.useRef();

  //Initial null profile descriptor states
  const [userProfileID, setUserProfileID] = useState(
    location.state.hasOwnProperty("rcTotal") ? location.state.rcID : location.state.lcID
  );
  /* eslint-disable no-unused-vars  */
  const [userProfileLangId, setUserProfileLangId] = useState(null);
  const [userProfileLangName, setUserProfileLangName] = useState(null);
  const [userProfileProfLevel, setUserProfileProfLevel] = useState(null);
  const [userProfileThisIndex, setUserProfileThisIndex] = useState(null);
  /* eslint-enable no-unused-vars  */
  const [userProfile, setUserProfile] = useState(null);
  const [state, setState] = useState({
    language: null,
    languageFull: null,
    modality: null,
    contentScore: 0,
    linguisticScore: 0,
    contentScoreTarget: 0,
    linguisticScoreTarget: 0,
    estimatedLevel: null,
    targetLevel: null,
    endTime: null,
    timeSpent: null,
    edgeCase: null,
    planModal: false,
    userProfile: location.state,
    rcDashboardDP: location.state.rcDashboardDP,
    lcDashboardDP: location.state.lcDashboardDP
  });
  const targetLevelKey = {
    "-999": "1",
    0: "1+",
    1: "2",
    2: "2+",
    3: "3",
    4: "***"
  };
  axiosRetry(axios, {
    retries: 4, // number of retries

    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000mst
    retryCondition: (err) => {
      console.log(err.response);
      return (
        axiosRetry.isNetworkError(err) ||
        err.code === "ECONNABORTED" ||
        err.code === "ENOTFOUND" ||
        err.code === "ETIMEDOUT" ||
        (err.response && err.response.status >= 500 && err.response.status <= 599)
      );
    }
  });

  //printing using print dialog
  useEffect(() => {
    function handleBeforePrint() {
      // Perform actions before the print dialog opens
      // console.log("Before print");
      
    }

    function handleAfterPrint() {
      // Perform actions after the print dialog closes
      // console.log("After print");
      const element = document.querySelector(".bodyItems");
      let currentSelectionElsAgain = element.querySelectorAll(".active");
      currentSelectionElsAgain.forEach((el) => {
        if (el.classList.contains("temporaryActive")) {
          el.classList.remove("temporaryActive");
        } else {
          el.classList.remove("active");
        }
      });
      let arrowsToHide = element.querySelectorAll(".centerer :nth-child(1)");
      arrowsToHide.forEach((arrow) => {
        if (arrow.style !== null) {
          arrow.style.display = "inline-block";
        }
      });
      let theEls2 = element.querySelectorAll(".grInfo p.infoTagline, .grInfo button");
      theEls2.forEach((el) => {
        el.style.visibility = "visible";
      });
      element.querySelector(".tabContent").style.border = "1px solid #ddd";
      // document.querySelector("meta[name=viewport]").setAttribute("content", "width=device-width, initial-scale=1");
    }

    window.addEventListener("beforeprint", handleBeforePrint);
    window.addEventListener("afterprint", handleAfterPrint);

    // Cleanup the event listeners on component unmount
    return () => {
      window.removeEventListener("beforeprint", handleBeforePrint);
      window.removeEventListener("afterprint", handleAfterPrint);
    };
  }, []);

  //gather initial profile state
  useEffect(() => {
    if (location.state.hasOwnProperty("rcTotal")) {
      //console.log(location.state.rcTotal);
      let workingElement;
      if (!Array.isArray(location.state.rcTotal)) {
        workingElement = [location.state.rcTotal];
      } else {
        workingElement = location.state.rcTotal;
      }
      // console.log(workingElement);
      workingElement.forEach((item, index) => {
        //console.log(item);
        //console.log(item.currentLevel);
        if (item._id === userProfileID) {
          // console.log(index);
          // console.log(item);
          setUserProfileThisIndex(index);
          setUserProfileProfLevel(item.currentLevel);
          setUserProfileLangId(item.language._id);
          setUserProfileLangName(item.language.displayName);

          //console.log(workingElement[index]);
          axios
            .get("api/sessionRC/diagProfileByProfLevel", {
              params: {
                sessionId: encodeURIComponent(userProfileID),
                proficiencyLevel: item.currentLevel ? item.currentLevel : encodeURIComponent(null),
                language: encodeURIComponent(item.language._id)
              }
            })
            .then((response) => {
              //console.log(response);
              // console.log(workingElement[index].skillFloor);
              // console.log(targetLevelKey[workingElement[index].skillFloor]);
              if (response.data.length > 0) {
                if (workingElement[index].skillFloor !== 4) {
                  setState({
                    ...state,
                    language: workingElement[index].language._id,
                    languageFull: workingElement[index].language.displayName,
                    modality: workingElement[index].modality,
                    endTime: workingElement[index].endtime,
                    timeSpent: workingElement[index].timeSpent,
                    contentScore: response.data[0].contentPercentage,
                    linguisticScore: response.data[0].linguisticPercentage,
                    contentScoreTarget: response.data[0].contentScoreTarget,
                    linguisticScoreTarget: response.data[0].linguisticScoreTarget,
                    estimatedLevel: workingElement[index].currentLevel,
                    targetLevel:
                      workingElement[index].skillFloor !== null
                        ? targetLevelKey[workingElement[index].skillFloor]
                        : workingElement[index].targetLevel,
                    edgeCase: false
                  });
                } else {
                  setState({
                    ...state,
                    language: workingElement[index].language._id,
                    languageFull: workingElement[index].language.displayName,
                    modality: workingElement[index].modality,
                    endTime: workingElement[index].endtime,
                    timeSpent: workingElement[index].timeSpent,
                    contentScore: response.data[0].contentPercentage,
                    linguisticScore: response.data[0].linguisticPercentage,
                    contentScoreTarget: response.data[0].contentScoreTarget,
                    linguisticScoreTarget: response.data[0].linguisticScoreTarget,
                    estimatedLevel: workingElement[index].currentLevel,
                    targetLevel:
                      workingElement[index].skillFloor !== null
                        ? targetLevelKey[workingElement[index].skillFloor]
                        : workingElement[index].targetLevel,
                    edgeCase: "above"
                  });
                }
                setUserProfile(response.data);
              } else {
                setState({
                  ...state,
                  language: workingElement[index].language._id,
                  languageFull: workingElement[index].language.displayName,
                  modality: workingElement[index].modality,
                  endTime: workingElement[index].endtime,
                  timeSpent: workingElement[index].timeSpent,
                  contentScore: null,
                  linguisticScore: null,
                  contentScoreTarget: null,
                  linguisticScoreTarget: null,
                  estimatedLevel: workingElement[index].currentLevel,
                  targetLevel:
                    workingElement[index].language._id === "eng"
                      ? "1+"
                      : workingElement[index].skillFloor !== null
                      ? targetLevelKey[workingElement[index].skillFloor]
                      : workingElement[index].targetLevel,
                  edgeCase: "below"
                });
                setUserProfile(response.data);
              }
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.status >= 400 && error.response.status < 600) {
                  //global error dispatch call to show error modal if error received during api call
                  errorDispatch({
                    type: "UPDATE_ERROR_STATE",
                    payload: {
                      errorStatusCode: error.response.status,
                      errorUserMessage: "New Error",
                      errorDevData: error.response
                    }
                  });
                }
              }
            });
        }
      });
    } else if (location.state.hasOwnProperty("lcTotal")) {
      //console.log(location.state.lcTotal);
      let workingElement;
      if (!Array.isArray(location.state.lcTotal)) {
        workingElement = [location.state.lcTotal];
      } else {
        workingElement = location.state.lcTotal;
      }
      workingElement.forEach((item, index) => {
        if (item._id === userProfileID) {
          //console.log(item);
          //console.log(item.currentLevel);
          setUserProfileThisIndex(index);
          setUserProfileProfLevel(item.currentLevel);
          setUserProfileLangId(item.language._id);
          setUserProfileLangName(item.language.displayName);
          //console.log(workingElement[index]);
          axios
            .get("api/sessionLC/diagProfileByProfLevel", {
              params: {
                sessionId: encodeURIComponent(userProfileID),
                proficiencyLevel: item.currentLevel ? item.currentLevel : encodeURIComponent(null),
                language: encodeURIComponent(item.language._id)
              }
            })
            .then((response) => {
              //console.log(response.data);
              if (response.data.length > 0) {
                if (workingElement[index].skillFloor !== 4) {
                  //console.log("Edge Case: false");
                  setState({
                    ...state,
                    language: workingElement[index].language._id,
                    languageFull: workingElement[index].language.displayName,
                    modality: workingElement[index].modality,
                    endTime: workingElement[index].endtime,
                    timeSpent: workingElement[index].timeSpent,
                    contentScore: response.data[0].contentPercentage,
                    linguisticScore: response.data[0].linguisticPercentage,
                    contentScoreTarget: response.data[0].contentScoreTarget,
                    linguisticScoreTarget: response.data[0].linguisticScoreTarget,
                    estimatedLevel: workingElement[index].currentLevel,
                    targetLevel: targetLevelKey[workingElement[index].skillFloor],
                    edgeCase: false
                  });
                } else {
                  //console.log("Edge Case: above");
                  setState({
                    ...state,
                    language: workingElement[index].language._id,
                    languageFull: workingElement[index].language.displayName,
                    modality: workingElement[index].modality,
                    endTime: workingElement[index].endtime,
                    timeSpent: workingElement[index].timeSpent,
                    contentScore: response.data[0].contentPercentage,
                    linguisticScore: response.data[0].linguisticPercentage,
                    contentScoreTarget: response.data[0].contentScoreTarget,
                    linguisticScoreTarget: response.data[0].linguisticScoreTarget,
                    estimatedLevel: workingElement[index].currentLevel,
                    targetLevel: targetLevelKey[workingElement[index].skillFloor],
                    edgeCase: "above"
                  });
                }
                setUserProfile(response.data);
              } else {
                //console.log("Edge Case: below");
                setState({
                  ...state,
                  language: workingElement[index].language._id,
                  languageFull: workingElement[index].language.displayName,
                  modality: workingElement[index].modality,
                  endTime: workingElement[index].endtime,
                  timeSpent: workingElement[index].timeSpent,
                  contentScore: null,
                  linguisticScore: null,
                  contentScoreTarget: null,
                  linguisticScoreTarget: null,
                  estimatedLevel: workingElement[index].currentLevel,
                  targetLevel:
                    workingElement[index].language._id === "eng"
                      ? "1+"
                      : targetLevelKey[workingElement[index].skillFloor],
                  edgeCase: "below"
                });
                setUserProfile(response.data);
              }
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.status >= 400 && error.response.status < 600) {
                  //global error dispatch call to show error modal if error received during api call
                  errorDispatch({
                    type: "UPDATE_ERROR_STATE",
                    payload: {
                      errorStatusCode: error.response.status,
                      errorUserMessage: "New Error",
                      errorDevData: error.response
                    }
                  });
                }
              }
            });
        }
      });
    }
  }, [location.state]);

  useEffect(() => {
    if (userProfile !== null) {
      diagnosticProfileFunctions();
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      window.scrollTo(0, 0);
      window.addEventListener("popstate", (e) => {
        window.location.reload();
      });
    }
  }, [userProfile]);

  const handleReadingSurveyModalState = useCallback(
    (e) => {
      setState({ ...state, rcDashboardDP: true });
    },
    [state]
  );

  const handleListeningSurveyModalState = useCallback(
    (e) => {
      setState({ ...state, lcDashboardDP: true });
    },
    [state]
  );

  return (
    <div>
      {userProfile !== null ? (
        <>
          <div className="bodyItems">
            <div ref={pdfSegment}>
              <Overview
                language={state.language}
                languageFull={state.languageFull}
                modality={state.modality}
                endTime={state.endTime}
                timeSpent={state.timeSpent}
                contentScore={state.contentScore}
                linguisticScore={state.linguisticScore}
                estimatedLevel={state.estimatedLevel}
                onPrint={print}
              />
              {state.edgeCase === false &&
              state.edgeCase !== null &&
              state.edgeCase !== "below" &&
              !(state.targetLevel === "+++" || state.targetLevel === "***") ? (
                <Details
                  id={userProfileID}
                  userProfile={userProfile}
                  language={state.language}
                  modality={state.modality}
                  estimatedLevel={state.estimatedLevel}
                  targetLevel={state.targetLevel}
                />
              ) : state.edgeCase === "below" ? (
                <Details
                  id={userProfileID}
                  userProfile={userProfile}
                  language={state.language}
                  modality={state.modality}
                  estimatedLevel={state.edgeCase}
                  targetLevel={state.targetLevel}
                />
              ) : state.edgeCase === "above" ? (
                <Details
                  id={userProfileID}
                  userProfile={userProfile}
                  language={state.language}
                  modality={state.modality}
                  estimatedLevel={state.estimatedLevel}
                  targetLevel={state.edgeCase}
                />
              ) : (
                <></>
              )}
            </div>
            {(location.state.lcID !== undefined || location.state.rcID !== undefined) && (
              <MoreInfo
                xrID={state.modality === "reading" ? location.state.rcID : location.state.lcID}
                xrTotal={state.modality === "reading" ? location.state.rcTotal : location.state.lcTotal}
                targetRef={pdfSegment}
                targetLevel={state.targetLevel}
                language={state.language}
                modality={state.modality}
                estimatedLevel={state.estimatedLevel}
              />
            )}
          </div>
          <Footer showReportProblem={false} />
          {!state.rcDashboardDP && state.modality === "reading" && (
            <ReadingSurveyModal handleReadingSurveyModalState={handleReadingSurveyModalState} />
          )}
          {!state.lcDashboardDP && state.modality === "listening" && (
            <ListeningSurveyModal handleListeningSurveyModalState={handleListeningSurveyModalState} />
          )}
        </>
      ) : (
        <LoadingSpinner msg="Gathering assessment details..." />
      )}
    </div>
  );
};

export default Profile;
