import React, { useState, useEffect, useContext } from "react";
import { ErrorContext } from "../context/ErrorProvider";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "../components/footer/Footer";
import axios from "axios";
import axiosRetry from "axios-retry";
import LoadingSpinner from "../components/spinner/LoadingSpinner";
import Auth from "../contents/Auth";

/**
 * @component ResetPassword
 * @description allows a user to reset their password either from editProfile or forgotPassword
 **/

const ResetPassword = (props) => {
  let { resetToken } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [, errorDispatch] = useContext(ErrorContext);
  const [token, setToken] = useState(null);
  const [email, setEmail] = useState(null);
  const [sentResetToken, setSentResetToken] = useState(false);
  const [validatedResetToken, setValidatedResetToken] = useState(false);

  const [state, setState] = useState({
    email: "",
    updated: false,
    isLoading: true,
    error: false,
    frontEndErrors: {},
    input: {}
  });
  axiosRetry(axios, {
    retries: 4, // number of retries

    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000mst
            retryCondition: (err) => {return axiosRetry.isNetworkError(err) || err.code === "ECONNABORTED" || err.code === "ENOTFOUND" || err.code === "ETIMEDOUT" || (err.response && err.response.status >= 500 && err.response.status <= 599) }


  });

  useEffect(() => {
    if (location.state !== null) {
      if (location.state.hasOwnProperty("token")) {
        setToken(location.state.token);
      }
      if (location.state.hasOwnProperty("email")) {
        setEmail(location.state.email);
        setState({ ...state, email: location.state.email });
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (resetToken !== undefined && resetToken !== null) {
      setToken(resetToken);
    }
  }, [resetToken]);

  useEffect(() => {
    if (token === null && !sentResetToken && resetToken === undefined && email !== null) {
      axios
        .post("/api/forgotPassword", {
          email
        })
        .then((response) => {
          setSentResetToken(true);
        })
        .catch((error) => {
          errorDispatch({
            type: "UPDATE_ERROR_STATE",
            payload: {
              errorStatusCode: error.response.status,
              errorUserMessage: "New Error",
              errorDevData: error.response
            }
          });
        });
    }
  }, [email, token, sentResetToken, resetToken]);

  useEffect(() => {
    if (token !== undefined && token !== "" && token !== null) {
      axios
        .get("/api/resetPassword", { params: { token } })
        .then((response) => {
          if (
            response.data.message === "Password reset Token is valid." ||
            response.data.message === "Token is verified."
          ) {
            setState({ ...state, email: response.data.email, updated: false, isLoading: false, error: false });
            setEmail(response.data.email);
            setValidatedResetToken(true);
          }
        })
        .catch((error) => {
          setState({ ...state, updated: false, isLoading: false, error: true });
          setValidatedResetToken("invalid");
          if (error.response) {
            if (error.response.status >= 400 && error.response.status < 600 && error.response.status !== 403) {
              //global error dispatch call to show error modal if error received during api call
              errorDispatch({
                type: "UPDATE_ERROR_STATE",
                payload: {
                  errorStatusCode: error.response.status,
                  errorUserMessage: "New Error",
                  errorDevData: error.response
                }
              });
            }
          }
        });
    } else {
      if (state.isLoading === true) {
        setState({ ...state, updated: false, error: false, isLoading: false });
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const handleChange = (event) => {
    let input = state.input;
    input[event.target.name] = event.target.value;
    setState({ ...state, input });
  };

  const updatePassword = async (e) => {
    e.preventDefault();
    if (validateUser()) {
      const password = state.input["password"];

      try {
        const response = await axios.post("/api/updatePassword/", {
          email,
          password,
          token
        });
        if (response.data.message === "Password Updated") {
          setState({ ...state, updated: true, error: false });
          document.querySelector(".resetForm").style.display = "none";
          setTimeout(function () {
            Auth.logout();
          }, 5000);
        } else {
          setState({ ...state, updated: false, error: true });
        }
      } catch (error) {
        //console.log(error.response.data);
      }
    }
  };

  const validateUser = () => {
    let input = state.input;
    let frontEndErrors = {};
    let isValid = true;

    if (typeof input["password"] !== "undefined") {
      const passwordRegEx = new RegExp(
        /(?=^.{8,16}$)(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?!.*\s).*$/ //at least one uppercase letter, one lowercase letter, and one number
      );

      if (!passwordRegEx.test(input["password"])) {
        isValid = false;
        frontEndErrors["password"] = "Your password must contain uppercase, lowercase, number";
      }
    }

    if (!input["password"]) {
      isValid = false;
      frontEndErrors["password"] = "Please enter your password.";
    }

    if (!input["password2"]) {
      isValid = false;
      frontEndErrors["password2"] = "Please confirm your password.";
    }

    if (typeof input["password"] !== "undefined") {
      if (input["password"].length < 8) {
        isValid = false;
        frontEndErrors["password"] = "Passwords must be 8 or more characters.";
      }
    }

    if (typeof input["password"] !== "undefined") {
      if (input["password"].length > 16) {
        isValid = false;
        frontEndErrors["password"] = "Maximum password length is 16 characters.";
      }
    }

    if (typeof input["password"] !== "undefined" && typeof input["password2"] !== "undefined") {
      if (input["password"] !== input["password2"]) {
        isValid = false;
        frontEndErrors["password"] = "Passwords must match.";
      }
    }

    setState({ ...state, frontEndErrors: frontEndErrors });

    return isValid;
  };

  const goHome = () => {
    navigate("/");
  };

  const forgotPassword = () => {
    navigate("/forgot");
  };

  const showHidePassword = (event) => {
    let label = "#" + event.target.accessKey;
    let password = document.querySelector(label);
    password.type = password.type === "password" ? "text" : "password";
    password.focus();
  };

  const { error, isLoading, updated } = state;

  return (
    <React.Fragment>
      {(!sentResetToken || isLoading) && !token ? (
        <div className="signUp">
          Sending password reset link to {email}...
          <LoadingSpinner msg="" />
        </div>
      ) : error ? (
        <div className="signUp">
          <div>
            <h4>Your password reset token has expired. </h4>
            <small>Please request a new password reset token using the Change Password button below.</small>
            <br />
            <br />
            <button className="loginBtn" onClick={forgotPassword}>
              Change Password
            </button>
            <button className="loginBtn" onClick={goHome}>
              Go Home
            </button>
          </div>
        </div>
      ) : sentResetToken && !token ? (
        <div className="signUp">
          <div>
            A password reset link has been sent to {email}.
            <br />
            <br />
            Please click this link to update your password.
            <br />
            <br />
            <button className="loginBtn" onClick={goHome}>
              Return to Home
            </button>
          </div>
        </div>
      ) : (
        validatedResetToken &&
        token && (
          <>
            <div className="signUp">
              <span className="cardTitle">Password Reset</span>
              <form noValidate className="resetForm" onSubmit={updatePassword}>
                <label htmlFor="password1">
                  Password
                  <input
                    id="password1"
                    type="password"
                    className="password"
                    name="password"
                    value={state.password}
                    onChange={handleChange}
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                  <div className="text-danger">{state.frontEndErrors.password}</div>
                  <span
                    className="eye password"
                    title="Show/Hide password"
                    accessKey="password1"
                    onClick={showHidePassword}
                  />
                </label>
                <label htmlFor="password">
                  Re-enter password
                  <input
                    id="password2"
                    type="password"
                    className="password"
                    name="password2"
                    value={state.password2}
                    onChange={handleChange}
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                  <div className="text-danger">{state.frontEndErrors.password2}</div>
                  <span
                    className="eye password2"
                    title="Show/Hide password"
                    accessKey="password2"
                    onClick={showHidePassword}
                  />
                </label>
                <div className="passwordRules">
                  Password Requirements
                  <ul>
                    <li>Between 8 and 16 characters</li>
                    <li>At least one lower case letter</li>
                    <li>At least one upper case letter</li>
                    <li>At least one number</li>
                  </ul>
                </div>

                <button className="loginBtn">Update Password</button>
              </form>
              <br /> <br />
              {updated && (
                <div>
                  Your password has been successfully updated.
                  <br />
                  <br />
                  You have been logged out.
                  <br />
                  <br />
                  Please sign in with your new password.
                  <br />
                  <br />
                  <button className="loginBtn" onClick={goHome}>
                    Go Home
                  </button>
                </div>
              )}
            </div>
            <br />
          </>
        )
      )}
      <Footer showReportProblem={false} />
    </React.Fragment>
  );
};

export default ResetPassword;
