import React, { useRef, useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import dompurify from "dompurify";
import { IoPlay } from "react-icons/io5";
import { IoPause } from "react-icons/io5";
import { GiSpeaker } from "react-icons/gi";
import { IoIosRewind } from "react-icons/io";
import { IoMdFastforward } from "react-icons/io";

// export default function AnswerOption(props) {
const AnswerOption = (props) => {
  const LO = props.LO;
  const sanitizer = dompurify.sanitize;
  const lang = props.lang;
  const RTL = props.dir;
  const dir = RTL ? "RTL" : "";
  const question = props.question;
  const len = props.question.length;
  const [thisModValue, setThisModValue] = useState("");
  const audioPlayerAns = useRef(null);

  const createISO = (n) => {
    let num = n * 3;
    let iso = []; //iso = isolated
    for (let i = 0; i < len; i++) {
      if (i % 3 === 0) {
        iso.push("audio/" + lang + "/" + LO + "/" + question[num].value);
        iso = iso.map((item) => item.replace(/\s/g, "_"));
        return iso;
      }
    }
  };

  const createCont = (n) => {
    let num = n * 3 + 1;
    let cont = []; //cont = content
    for (let i = 0; i < len; i++) {
      if (i % 3 === 1) {
        cont.push("audio/" + lang + "/" + LO + "/" + question[num].value);
        cont = cont.map((item) => item.replace(/\s/g, "_"));
        return cont;
      }
    }
  };

  const createTrans = (n) => {
    let num = n * 3 + 2;
    let trans = [];
    for (let i = 0; i < len; i++) {
      if (i % 3 === 2) {
        const curPassage = question[num].value;
        let newPassage = curPassage.replace(/<u>/gi, '<span class="textHighlight">');
        newPassage = newPassage.replace(/<\/u>/gi, "</span>");
        trans.push(newPassage);
        return trans;
      }
    }
  };

  const createAnswerField = () => {
    const len = props.question.length;
    let items = [];
    for (let i = 0; i < len / 3; i++) {
      const mainID = props.actID + "_" + i + "_0";
      const modID = props.actID + "_" + i + "_1";
      const itemId = props.actID + "_" + i;
      const transcriptId = "trans_" + i;

      items.push(
        <div className="holder">
          {/* <span className="inlinePlay paused" file={createISO(i)}>
            Item {i + 1}
          </span> */}
          <div className="audioQ forceInlineBlock" key={createISO(i)}>
            <AudioPlayer
              autoPlay={false}
              autoPlayAfterSrcChange={false}
              header={"Item " + parseInt(i + 1)}
              footer={null}
              hasDefaultKeyBindings={false}
              customAdditionalControls={[]}
              customVolumeControls={[]}
              ref={audioPlayerAns["inlineISO-" + i]}
              src={createISO(i)}
              showJumpControls={false}
              showDownloadProgress={false}
              showFilledProgress={false}
              customIcons={{
                play: <IoPlay />,
                pause: <IoPause />,
                rewind: <IoIosRewind />,
                forward: <IoMdFastforward />,
                volume: <GiSpeaker />
              }}
              // onPlay={(e) => console.log("onPlay")}
            />
          </div>
          &nbsp;&nbsp;
          <div className="audioQ forceInlineBlock" key={createCont(i)}>
            <AudioPlayer
              autoPlay={false}
              autoPlayAfterSrcChange={false}
              header={"Item In Context"}
              footer={null}
              hasDefaultKeyBindings={false}
              customAdditionalControls={[]}
              customVolumeControls={[]}
              ref={audioPlayerAns["inlineIIC-" + i]}
              src={createCont(i)}
              showJumpControls={false}
              showDownloadProgress={false}
              showFilledProgress={false}
              customIcons={{
                play: <IoPlay />,
                pause: <IoPause />,
                rewind: <IoIosRewind />,
                forward: <IoMdFastforward />,
                volume: <GiSpeaker />
              }}
              // onPlay={(e) => console.log("onPlay")}
            />
          </div>
          <label htmlFor="xlate1">
            English Meaning
            <form autoComplete="off">
              <input
                spellCheck={true}
                autoComplete="off"
                className="xlate mainText"
                type="text"
                id={mainID}
                onChange={props.onAnswerSelected}
                onBlur={props.onLoseFocusCheckNLP}
                onKeyDown={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
              <input
                spellCheck={true}
                autoComplete="off"
                style={{ display: "none", border: "1px solid #73b1da" }}
                className="xlate modText"
                type="text"
                id={modID}
                defaultValue={thisModValue}
                onChange={props.onAnswerSelectedMod}
                onBlur={props.onLoseFocusCheckNLP}
                onKeyDown={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            </form>
            {props.invalidEnInput.find((thisEl) => thisEl === mainID) && (
              <div className="invalidInputLanguageDiv">Please enter your answer in English.</div>
            )}
            {props.invalidEnInput.find((thisEl) => thisEl === modID) && (
              <div className="invalidInputLanguageDiv">Please enter your answer in English.</div>
            )}
          </label>
          <div className="clearFix" />
          <div className="LCMTranscription">
            <p id={itemId} className="trigger closed disabled" onClick={trigger}>
              Show transcription
            </p>
            <div className="accordion" style={{ display: "none" }}>
              <div
                className={[dir, "transcriptText noSelect"].join(" ")}
                id={transcriptId}
                dangerouslySetInnerHTML={{
                  __html: sanitizer(createTrans(i))
                }}
              />
            </div>
          </div>
        </div>
      );
    }
    return items;
  };

  // accordion for showing/hiding transcriptions
  const trigger = (event) => {
    const item = event.currentTarget;
    const itemId = item.id;
    handleTrigger(item, itemId);
  };

  function handleTrigger(trigger, itemId) {
    if (trigger.classList.contains("disabled")) {
      window.event.preventDefault();
      window.event.stopPropagation();

      var confirmDialog = window.confirm(
        "Use the transcript only if you cannot understand the words or phrases in the audio. Your answers, after viewing the transcript, will not be counted toward your final rating, but your scores will be shown in the diagnostic profile to provide you with additional feedback.\n\n Do you want to view the transcript?"
      );

      if (confirmDialog === true) {
        trigger.classList.remove("disabled");
        const mainId = "#" + itemId + "_0";
        const modId = "#" + itemId + "_1";
        document.querySelector(mainId).style.display = "none";
        document.querySelector(modId).style.display = "inline";
        //document.querySelector(modId).value = document.querySelector(mainId).value;
        setThisModValue(document.querySelector(mainId).value);
        // document.querySelector(modId).dispatchEvent(new Event("change"));
        props.onAnswerSelectedMod(null, itemId + "_1", document.querySelector(mainId).value);
      } else {
        return;
      }
    }
    accordion(trigger, itemId);
  }

  function accordion(trigger, itemId) {
    let clicked = "#" + itemId;
    document.querySelectorAll(".accordion").forEach(function (event) {
      event.style.display = "none";
    });

    document.querySelectorAll(".trigger").forEach(function (event) {
      event.textContent = "Show transcription";
    });

    if (document.querySelector(clicked).classList.contains("open")) {
      document.querySelectorAll(".trigger").forEach(function (event) {
        event.classList.remove("open");
      });

      document.querySelector(clicked).classList.add("closed");
    } else {
      document.querySelector(clicked).classList.remove("closed");

      document.querySelector(clicked).classList.add("open");

      document.querySelector(clicked).nextElementSibling.style.display = "block";

      document.querySelector(clicked).textContent = "Hide transcription";
    }
  }

  // // TODO: Only the parent audio leaves the accordion open
  // let inlinePlays = document.querySelectorAll(".LCM .inlinePlay");
  // inlinePlays.forEach(function (inlinePlay) {
  //   inlinePlay.addEventListener("click", function () {
  //     document.querySelectorAll(".accordion").forEach(function (event) {
  //       event.style.display = "none";
  //     });
  //   });
  // });

  return <>{createAnswerField()}</>;
};

export default AnswerOption;
